var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    _vm._g({}, _vm.$listeners),
    [
      _c(
        "v-expansion-panel-header",
        {
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c("v-icon", { staticClass: "icon", attrs: { left: "" } }, [
                    _vm._v(" mdi-chevron-down "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "font-weight-bold header" }, [
            _vm._v("Thank you"),
          ]),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        [
          _c("color-input-row", {
            attrs: { "line-number": "1", title: "Title text color" },
            model: {
              value: _vm.value.titleTextColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "titleTextColor", $$v)
              },
              expression: "value.titleTextColor",
            },
          }),
          _c("v-divider"),
          _c("color-input-row", {
            attrs: { "line-number": "2", title: "Survey hyperlink text color" },
            model: {
              value: _vm.value.surveyHyperlinkTextColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "surveyHyperlinkTextColor", $$v)
              },
              expression: "value.surveyHyperlinkTextColor",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }